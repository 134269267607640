<template>
    <div class="table_list_events">
        <div class="table_head">
            <div class="cover_end_like">
                <div class="change_view_events">
                    <button type="button" class="_map" :class="{'active': viewEvents ==='map'}" @click="changeViewAlbums('map')"></button>
                    <button type="button" class="_list" :class="{'active': viewEvents ==='list'}" @click="changeViewAlbums('list')"></button>
                </div>
            </div>
            <div class="name">Name
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="organizer">Organizer
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="date">Date
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="duration">Duration
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="location">Location
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="price">Price
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="more"></div>
        </div>

        <div class="skeleton-loader" v-if="eventsLoading">
            <v-skeleton-loader
                type="image"
                class="item_list_event"
                v-for="index in 10"
                :key="index"
            ></v-skeleton-loader>
        </div>


        <template v-if="!eventsLoading">
            <div class="table_row item_list_event" v-for="event of events" :key="event.id">
                <div class="cover_end_like">
                    <div class="cover_event">
                        <img :src="apiUrl.slice(0, -1) + `${event.images[0].contentUrl}`" alt="photo">
                    </div>
                    <button type="button" class="like"></button>
                </div>
                <div class="name">
                    <router-link :to="{name: 'eventDetails', params: {id: event.id}}" class="name">{{ event.title }}</router-link>
                </div>
                <div class="organizer">{{ event.owner.nickname }}</div>
                <div class="date">{{ event.dateStart | moment("MMM DD") }}</div>
                <div class="duration">
                    {{ event.dateEnd | moment('from', event.dateStart, true) }}
                </div>
                <div class="location">{{ JSON.parse(event.location).locality }}, {{ JSON.parse(event.location).country }}</div>
                <div class="price">
                    <template v-if="event.price">{{ event.price }}</template>
                    <template v-else>-</template>
                </div>
                <div class="more">
                    <v-menu
                        transition="slide-y-transition"
                        :close-on-content-click="false"
                        :nudge-width="120"
                        offset-y
                        nudge-left="60"
                        nudge-bottom="15"
                        origin="top center"
                        content-class="menu-pointer charcoal"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <button class="more_btn" v-ripple v-bind="attrs" v-on="on"></button>
                        </template>
                        <v-list>
                            <v-list-item v-ripple>
                                <button type="button" @click="deleteEvent(event.id)">Delete Event</button>
                            </v-list-item>
                            <!--                            <v-list-item v-ripple disabled>-->
                            <!--                                <button type="button">Action Two</button>-->
                            <!--                            </v-list-item>-->
                        </v-list>
                    </v-menu>

                </div>
            </div>
        </template>
        <div class="no_events_found" v-if="!eventsLoading && !events.length">No events found</div>
        <pagination v-if="events.length && countEvents > 30"
                    :count="countEvents"
                    :currentPage="currentPage"
                    :commit="'SET_EVENTS_CURRENT_PAGE'">
        </pagination>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import pagination from "@/components/pagination";

export default {
    name: "listEvents",
    props: ['events'],
    components: {
        pagination
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
        }
    },
    computed: {
        ...mapGetters({
            viewEvents: 'events/VIEW_EVENTS',
            eventsLoading: 'events/EVENTS_LOADING',
            countEvents: 'events/COUNT_EVENTS',
            currentPage: 'events/CURRENT_PAGE',
        })
    },
    methods: {
        ...mapActions({
            updateViewAlbums: 'events/UPDATE_VIEW_EVENTS',
            deleteItemEvent: 'events/DELETE_EVENT'
        }),
        changeViewAlbums(view) {
            this.updateViewAlbums(view);
        },
        deleteEvent(id) {
            let params = {
                id: id
            }
            this.deleteItemEvent(params);
        }

    }
}
</script>

<style scoped>

</style>