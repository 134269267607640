<template>
<v-container>
    <div :class="name">
        <div class="heading_events">
            <h4>{{headingName}}</h4>
            <button type="button" class="view_all" v-ripple>View all</button>
        </div>

        <div class="wrap_this_events">
            <div class="event_box" v-for="event of events.slice(0, 4)" :key="event.id"  @click="redirectToEvent(event.id)">
                <div class="event_img">
                    <img :src="apiUrl.slice(0, -1) + `${event.images[0].contentUrl}`" alt="photo">
                    <div class="active_overlay">
                        <button type="button" class="like" @click.stop="addLike(album.id)"></button> <!-- v-if="event.isFavorite === false" -->
                        <button type="button" class="like active" v-if="event.isFavorite === true" @click.stop="removeLike(album.id)"></button>
                    </div>
                </div>
                <div class="event_description">
                    <div class="name">{{event.title}}</div>
                    <div class="location">{{JSON.parse(event.location).locality}}, {{JSON.parse(event.location).country}}</div>
                    <div class="date">
<!--                        SAT, Sep 18 at 19:00 EDT-->
<!--                        {{event.dateStart}}-->
                        {{event.dateStart | moment("dd, MMM DD")}}
                        at
                        {{event.dateStart | moment("HH:mm")}} EDT
                    </div>
                    <div class="genres">
                        {{ event.genre[0]}},
                        {{ event.genre[1]}}
                        and more
                    </div>
                </div>
            </div>
        </div>
    </div>
</v-container>
</template>

<script>
export default {
    name: "eventsRowSection",
    props: ['name', 'events'],
    data() {
        return {
            headingName: this.name,
            apiUrl: process.env.VUE_APP_API_URL,
        }
    },
    created() {
        this.getHeading();
    },
    methods: {
        getHeading() {
            let firstWord = this.name.split('_')[0];
            let twoWord = this.name.split('_')[1];
            if (firstWord && twoWord) {
                return this.headingName = `${this.name.split('_')[0]} ${this.name.split('_')[1]}`;
            } else {
                return this.headingName = `${this.name.split('_')[0]}`;
            }
        },
        redirectToEvent(eventID) {
            this.$router.push({name: 'eventDetails', params: {id: eventID}});
        },
        addLike() {

        },
        removeLike() {

        }
    }
}
</script>

<style scoped>

</style>