<template>
    <eventsLayout>
        <div class="page_front_event">

            <div class="wrap_carousel">
                <div class="background" v-if="activeSlide && activeSlide.images[0] && activeSlide.images[0].contentUrl"
                     :style="{'background-image': 'url('+(apiUrl.slice(0, -1) + `${activeSlide.images[0].contentUrl}`)+')'}">
                </div>
                <v-container>
                    <v-carousel v-model="slide" :height="445" hide-delimiters :show-arrows="false" :cycle="true">
                        <v-carousel-item
                            v-for="(itemSlide, i) in events" :key="i"
                            reverse-transition="fade-transition"
                            transition="fade-transition">
                            <v-sheet
                                color="#141E35"
                                height="100%"
                                tile>

                                <div class="slide_info">
                                    <div class="event_info">
                                        <div class="slide_name">{{ itemSlide.title }}</div>
                                        <div class="location">
                                            {{ JSON.parse(itemSlide.location).locality }},
                                            {{ JSON.parse(itemSlide.location).administrative_area_level_1 }}
                                        </div>
                                        <div class="dates">
                                            {{ itemSlide.dateStart | moment("MMM DD") }} -
                                            {{ itemSlide.dateEnd | moment("MMM DD") }}
                                        </div>
                                        <div class="description">
                                            {{ itemSlide.description.slice(0, 300) }}
                                            <span v-if="itemSlide.description.length > 300">...</span>
                                        </div>
                                    </div>
                                    <div class="slide_navigation">
                                        <button type="button" class="prev change_slide" @click="slide--"></button>
                                        <div class="current_of_length">
                                            {{ slide + 1 }} / {{ events.length }}
                                        </div>
                                        <button type="button" class="next change_slide" @click="slide++"></button>
                                    </div>

                                </div>
                                <img v-if="itemSlide.images[0] && itemSlide.images[0].contentUrl" class="slide_image"
                                     :src="apiUrl.slice(0, -1) + `${itemSlide.images[0].contentUrl}`" alt="photo">
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </v-container>
            </div>


            <div class="main_container">

                <v-container class="wrap_filter_bar_events">
                    <div class="filter_bar_">
                        <div class="filters_">

                            <!-- GENRE -->
                            <div class="genre">
                                <div class="material_input">
                                    <input type="text" readonly required v-model="genre">
                                    <label>Genre</label>
                                </div>
                            </div>

                            <!-- LOCATION -->
                            <div class="location">
                                <div class="material_input">
                                    <input type="text" required v-model="location">
                                    <label>Location</label>
                                </div>
                            </div>

                            <!-- DATE -->
                            <div class="date">
                                <div class="material_input">

                                    <v-menu
                                        ref="menuDateRange"
                                        v-model="menuDateRange"
                                        :close-on-content-click="false"
                                        :return-value.sync="dateRange"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <input type="text" required v-bind="attrs" v-on="on" v-model="dateFormatted">
                                            <label>Date</label>
                                        </template>
                                        <v-date-picker
                                            v-model="dateRange"
                                            :weekday-format="getDay"
                                            range
                                            reactive
                                            no-title
                                            scrollable>
                                            <div class="select_days">
                                                <button type="button" @click="selectDays(30)">30 Days</button>
                                                <button type="button" @click="selectDays(60)">60 Days</button>
                                                <button type="button" @click="selectDays(90)">90 Days</button>
                                            </div>
                                        </v-date-picker>
                                    </v-menu>

                                </div>
                            </div>

                            <!-- DURATION -->
                            <div class="duration">
                                <div class="material_input">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <input type="text" required v-model="durationFilter" v-bind="attrs" v-on="on">
                                        </template>

                                        <v-list>
                                            <v-list-item @click="setDuration('Any')">Any</v-list-item>
                                            <v-list-item @click="setDuration('Single Day')">Single Day</v-list-item>
                                            <v-list-item @click="setDuration('Multi Day')">Multi Day</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <label>Duration</label>
                                </div>

                            </div>

                        </div>
                    </div>
                </v-container>

                <v-container>

                    <div class="wrap_events_result">

                        <template v-if="viewEvents === 'map'">
                            <mapEvents :events="events"></mapEvents>
                        </template>
                        <template v-if="viewEvents === 'list'">
                            <listEvents :events="events"></listEvents>
                        </template>

                    </div>


                </v-container>
            </div>


            <div class="wrap_difference_events">
                <eventsRowSection :name="'recently_viewed'" :events="recentlyViewedEvents"></eventsRowSection>
                <eventsRowSection :name="'upcoming'" :events="upcomingEvents"></eventsRowSection>
                <eventsRowSection :name="'popular'" :events="popularEvents"></eventsRowSection>
                <eventsRowSection :name="'new'" :events="newEvents"></eventsRowSection>
            </div>


        </div>
    </eventsLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';

import eventsLayout from "@/layouts/eventsLayout.vue";
import mapEvents from "@/components/events/mapEvents";
import listEvents from "@/components/events/listEvents";

import eventsRowSection from "@/components/events/eventsRowSection";

export default {
    name: "events",
    components: {
        eventsLayout,
        mapEvents,
        listEvents,
        eventsRowSection
    },
    data(vm) {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            slide: 0,
            activeSlide: '',

            genre: 'Psytrance',
            location: '',
            dateRange: [],
            duration: '',

            dateFormatted: '',
            menuDateRange: false,
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        }
    },
    updated() {
        this.moment = moment;
        // this.dynamicPhotoKey += 1;
    },
    watch: {
        'dateRange'() {
            this.dateFormatted = this.formatDate(this.dateRange);

            if (this.dateRange[0] && this.dateRange[1]) {
                // this.menuDateRange = false;
                this.saveDateRange();
                this.$refs.menuDateRange.save(this.dateRange);
            }
        },
        location() {
            this.getEventsList();
        },

        slide() {
            this.setActiveSlide();
        }
    },
    computed: {
        ...mapGetters({
            events: 'events/EVENTS',

            recentlyViewedEvents: 'events/VIEWED_EVENTS',
            upcomingEvents: 'events/UPCOMING_EVENTS',
            popularEvents: 'events/POPULAR_EVENTS',
            newEvents: 'events/NEW_EVENTS',

            // filters
            locationFilter: 'events/LOCATION',
            dateFilter: 'events/DATE',
            durationFilter: 'events/DURATION',
            viewEvents: 'events/VIEW_EVENTS'
        }),
    },
    created() {
        this.getEventsList();
        this.getRowSectionEvents();
        this.location = this.locationFilter || '';
    },
    mounted() {

    },
    methods: {
        ...mapActions({
            getEvents: 'events/GET_EVENTS',

            getRecentlyViewedEvents: 'events/GET_VIEWED_EVENTS',
            getUpcomingEvents: 'events/GET_UPCOMING_EVENTS',
            getPopularEvents: 'events/GET_POPULAR_EVENTS',
            getNewEvents: 'events/GET_NEW_EVENTS',
        }),

        // datepicker
        getDay(date) {
            let i = new Date(date).getDay(date);
            return this.daysOfWeek[i];
        },
        formatDate(dates) {
            let dateStart;
            let dateEnd;
            if (dates[0]) {
                const [year, month, day] = dates[0].split('-');
                dateStart = `${month}/${day}/${year}`;
            }
            if (dates[1]) {
                const [year2, month2, day2] = dates[1].split('-');
                dateEnd = `${month2}/${day2}/${year2}`;
            }
            if (dateStart && dateEnd) {
                console.log('dateStart && dateEnd', dateStart, dateEnd);
                return [`${dateStart} - ${dateEnd}`];
            }
        },
        saveDateRange() {
            let dateStart = this.dateRange[0];
            let dateEnd = this.dateRange[1];
            if (dateStart && dateEnd) {
                this.getEventsList();
            }
        },
        selectDays(days) {
            let today = moment().format("YYYY-MM-DD");
            let interval = moment().subtract(days, 'd').format("YYYY-MM-DD");

            this.dateRange = [interval, today];
        },
        setDuration(duration) {
            this.duration = duration;
            // this.$store.commit('events/SET_EVENT_DURATION', duration);
        },

        setActiveSlide() {
            for (let key of Object.keys(this.events)) {
                if (+key === this.slide) {
                    this.activeSlide = this.events[this.slide];
                }
            }
        },
        getEventsList() {
            let params = {};
            if (this.location) {
                this.$store.commit('events/SET_EVENT_LOCATION', this.location);
                params['location'] = this.location;
            }
            if (this.dateRange[0] && this.dateRange[1]) {
                params['dateStart[after]'] = this.dateRange[0];
                params['dateStart[before]'] = this.dateRange[1];
            }

            this.getEvents(params)
                .then(() => {
                    this.setActiveSlide();
                })
                .catch(err => console.log(`getEventsList ${err}`));
        },
        getRowSectionEvents() {
            let recentlyViewedParams = {};
            this.getRecentlyViewedEvents(recentlyViewedParams)
                .then(() => console.log(`getRecentlyViewedEvents, ${this.recentlyViewedEvents}`))
                .catch(err => {
                    console.error(`getRecentlyViewedEvents, ${err}`)
                })

            let upcomingParams = {};
            this.getUpcomingEvents(upcomingParams)
                .then(() => console.log(`getUpcomingEvents, ${this.upcomingEvents}`))
                .catch(err => {
                    console.error(`getUpcomingEvents, ${err}`)
                })

            let popularParams = {};
            this.getPopularEvents(popularParams)
                .then(() => console.log(`getPopularEvents, ${this.popularEvents}`))
                .catch(err => {
                    console.error(`getPopularEvents, ${err}`)
                })

            let newParams = {};
            this.getNewEvents(newParams)
                .then(() => console.log(`getNewEvents, ${this.newEvents}`))
                .catch(err => {
                    console.error(`getNewEvents, ${err}`)
                })

        }
    }
}
</script>

<style scoped>

</style>